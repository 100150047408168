








import Vue from 'vue';
import DashboardText from '~/components/dashboard/DashboardText.vue';
import ProgressOverview from '~/components/dashboard/ProgressOverview.vue';
import TestInstances from '~/components/dashboard/TestInstances.vue';

export default Vue.extend({
  name: 'DashboardPage',
  components: {TestInstances, DashboardText, ProgressOverview},
});
