import {Model} from '@vuex-orm/core';
import moment from 'moment';
import {
  Course, CourseStudent, Document, DocumentVersion, School, SchoolClass, StudentActiveVersion, StudentBlacklistDocument, StudentDevice, StudentDocument, StudentTestState, Test,
  TestGroup, TestGroupStudent, TestInstance, TestStudent,
} from '../index';

export class Student extends Model {
  static entity = 'students';

  static fields() {
    return {
      id: this.attr(null),
      login: this.attr(''),
      email: this.attr(''),
      forename: this.attr(''),
      surname: this.attr(''),
      phoneNr: this.attr(''),
      type: this.attr(''),
      deviceSlots: this.attr(0),
      devices: this.hasMany(StudentDevice, 'studentId'),
      updatedAt: this.attr(''),
      createdAt: this.attr(''),
      lastLoginAt: this.attr(''),
      previousLoginAt: this.attr(''),
      validFrom: this.attr(''),
      validUntil: this.attr(''),
      testStartFrom: this.attr(''),
      language: this.attr(''),
      school: this.belongsTo(School, 'schoolId'),
      schoolClass: this.belongsTo(SchoolClass, 'schoolClassId'),
      documents: this.belongsToMany(Document, StudentDocument, 'studentId', 'documentId'),
      courses: this.belongsToMany(Course, CourseStudent, 'studentId', 'courseId'),
      documentBlacklist: this.belongsToMany(Document, StudentBlacklistDocument, 'studentId', 'documentId'),
      activeVersions: this.belongsToMany(DocumentVersion, StudentActiveVersion, 'studentId', 'documentVersionId'),
      tests: this.belongsToMany(Test, TestStudent, 'studentId', 'testId'),
      testInstances: this.hasMany(TestInstance, 'studentId'),
      testGroups: this.belongsToMany(TestGroup, TestGroupStudent, 'studentId', 'testGroupId'),
      studentTestStates: this.hasMany(StudentTestState, 'studentId'),

      // foreignKeys
      schoolId: this.attr(null),
      schoolClassId: this.attr(null),
    };
  }

  id: string;
  schoolId: string;
  schoolClassId: string;
  login: string;
  email: string;
  hashedPassword: string;
  forename: string;
  surname: string;
  phoneNr: string;
  type: string;
  deviceSlots: number;
  devices: StudentDevice[];
  updatedAt: string;
  createdAt: string;
  lastLoginAt: string;
  previousLoginAt: string;
  validFrom: string;
  validUntil: string;
  testStartFrom: string;
  language: string;
  school: School;
  schoolClass: SchoolClass;
  documents: Document[];
  courses: Course[];
  documentBlacklist: Document[];
  activeVersions: DocumentVersion[];
  tests: Test[];
  testInstances: TestInstance[];
  testGroups: TestGroup[];
  studentTestStates: StudentTestState[];

  get fullName() {
    return `${this.forename} ${this.surname}`;
  }

  get isPresence(): boolean {
    return this.type === 'presence';
  }

  get isDistance(): boolean {
    return this.type === 'distance';
  }

  get isStaff(): boolean {
    return this.type === 'staff';
  }

  get isScriptUser(): boolean {
    return this.type === 'script';
  }

  get isTestStartActive(): boolean {
    if (!this.testStartFrom) {
      return true;
    }
    const today = new Date();
    const startDate = new Date(this.testStartFrom);
    const endDate = new Date(this.testStartFrom);
    endDate.setMonth(endDate.getMonth() + 18);
    return today >= startDate && today <= endDate;
  }

  get testStartDate(): Date {
    return new Date(this.testStartFrom);
  }

  get testEndDate(): Date {
    if (!this.testStartFrom) {
      return null;
    }
    const endDate = new Date(this.testStartFrom);
    endDate.setMonth(endDate.getMonth() + 18);
    return endDate;
  }
}
