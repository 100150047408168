import {Module} from 'vuex';
import {ExtraTryTests, Student, StudentTestState} from '~/models';
import store from '~/store/index';
import {api} from '~/util';

interface StudentState {
}

export const studentModule: Module<StudentState, any> = {
  namespaced: true,
  state: {},
  actions: {
    async fetchById({state}, id) {
      try {
        const response = await api.get(`/api/student/${id}`);
        await Student.insertOrUpdate({data: response.data});
      } catch (e) {
        await store.dispatch('snackbar/showError', e);
      }
    },
    async getExtraTries(context, studentId: number) {
      try {
        const response = await api.get(`/api/student/${studentId}/extra-tries`);
        await ExtraTryTests.insert({data: response.data.map(test => ({testId: test.id, studentId}))});
        return response.data;
      } catch (e) {
        await store.dispatch('snackbar/showError', e);
      }
    },
    async giveAdditionalTry({state}, payload: { testId: number; studentId: string }) {
      try {
        await api.put(`/api/student/extra-try`, payload);
        await ExtraTryTests.insert({data: payload});
      } catch (e) {
        await store.dispatch('snackbar/showError', e);
      }
    },
    async removeAdditionalTry({state}, payload: { testId: number; studentId: string }) {
      try {
        await api.put(`/api/student/extra-try/remove`, payload);
        await ExtraTryTests.delete(ett => ett.testId === payload.testId && ett.studentId === payload.studentId);
      } catch (e) {
        await store.dispatch('snackbar/showError', e);
      }
    },
    async assignPreExams(context, payload: any) {
      try {
        const response = await api.put(`/api/student/test-states/assign/${payload.studentId}`, payload.testIds);
        await StudentTestState.delete(entry => entry.studentId === payload.studentId);
        await StudentTestState.insert({data: response.data});
      } catch (e) {
        await store.dispatch('snackbar/showError', e);
      }
    },
    async authorizeTest(context, payload: any) {
      try {
        await api.put(`/api/student/test-states/authorize`, payload);
        await StudentTestState.insertOrUpdate({
          data: {
            studentId: payload.studentId,
            testId: payload.testId,
            state: payload.form.state,
            password: payload.form.password,
            autoInsight: payload.form.autoInsight,
          },
        });
      } catch (e) {
        await store.dispatch('snackbar/showError', e);
      }
    },
    async authorizeAllPreExams(context, payload: any) {
      try {
        await api.put(`/api/student/test-states/authorize-all`, payload);
        await StudentTestState.update({
          where: sts => sts.studentId === payload.studentId,
          data: {
            state: payload.form.state,
            password: payload.form.password,
            autoInsight: payload.form.autoInsight,
          },
        });
      } catch (e) {
        await store.dispatch('snackbar/showError', e);
      }
    },
    async removeTestAssignment(context, payload: any) {
      try {
        await api.delete(`/api/student/${payload.studentId}/test-state/${payload.testId}`);
        await StudentTestState.delete(entry => entry.studentId === payload.studentId && entry.testId === payload.testId);
      } catch (e) {
        await store.dispatch('snackbar/showError', e);
      }
    },
  },
  getters: {},
};
