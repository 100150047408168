import Vue from 'vue';
import VueRouter from 'vue-router';
import DashboardPage from '~/pages/DashboardPage.vue';

Vue.use(VueRouter);

const routes = [
  {path: '/', name: 'dashboard', component: DashboardPage},
  {path: '/test/:id', name: 'test-details', component: () => import(/* webpackChunkName: "test-details" */ '~/pages/TestDetailPage.vue')},
  {
    path: '/test/:testId/student/:studentId',
    name: 'student-test-details',
    component: () => import(/* webpackChunkName: "student-test-details" */ '~/pages/StudentTestDetailPage.vue'),
  },
  {path: '/presence-students', name: 'presence-students', component: () => import(/* webpackChunkName: "presence-students" */ '~/pages/users/PresenceStudentsPage.vue')},
  {path: '/distance-students', name: 'distance-students', component: () => import(/* webpackChunkName: "distance-students" */ '~/pages/users/DistanceStudentsPage.vue')},
  {path: '/script-users', name: 'script-users', component: () => import(/* webpackChunkName: "script-users" */ '~/pages/users/ScriptUsersPage.vue')},
  {path: '/staff', name: 'staff', component: () => import(/* webpackChunkName: "staff" */ '~/pages/users/StaffPage.vue')},
  {path: '/student/:id', name: 'student-details', component: () => import(/* webpackChunkName: "student-details" */ '~/pages/StudentDetailsPage.vue')},
  {path: '/progress-tracker', name: 'progress-tracker', component: () => import(/* webpackChunkName: "progress-tracker" */ '~/pages/ProgressTrackerPage.vue')},
  {path: '/test-instance/:id', name: 'test-instance-details', component: () => import(/* webpackChunkName: "test-instance-details" */ '~/pages/TestInstanceDetailsPage.vue')},
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
