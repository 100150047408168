export const en = {
  nav: {
    dashboard: 'Dashboard',
    presenceStudents: 'Presence Students',
    distanceStudents: 'Distance Students',
    staff: 'Staff',
    scriptUsers: 'Script Users',
    progressTracker: 'Progress Tracker',
  },
  p: {
    correctAnswer: 'Correct Answer | Correct Answers',
    licence: 'Licence | Licences',
    subject: 'Subject | Subjects',
    area: 'Area | Areas',
    subArea: 'Sub area | Sub areas',
    question: 'Question | Questions',
    staticQuestion: 'Static Question | Static Questions',
    testDelivery: 'Test Delivery | Test Deliveries',
    try: 'Try | Tries',
    minute: 'Minute | Minutes',
    answer: 'Answer | Answers',
    student: 'Student | Students',
    test: 'Test | Tests',
    testGroup: 'Test Group | Test Groups',
    testPractice: 'Test Practice | Test Practices',
    atoPreExam: 'ATO Pre-Exam | ATO Pre-Exams',
    preExam: 'Pre-Exam | Pre-Exams',
    progressTest: 'Progress-Test | Progress-Tests',
    finalTest: 'Final-Test | Final-Tests',
    pool: 'Pool | Pools',
  },
  msg: {
    latestUpdate: 'Last updated {time}',
    authorizeAllPreExams: {
      title: 'Authorize All Pre-Exams',
      text: 'The selected settings will be applied to ALL Pre-Exams currently assigned to the student. If differing settings have previously been selected for one or more Pre-Exams, these will hereby be uniformly overwritten.',
    },
    confirmExtraTry: {
      title: 'Confirm Extra Try',
      text: 'This Pre-Exam has previously been passed. Please confirm, that this student should take this Pre-Exam again.',
    },
    additionalTryGiven: 'An additional try was given to the student.',
    noAnswerGiven: 'No answer given',
    dashboard: {
      login: `Hello {0},{1}
Your last login was on {2}.`,
      hello: `Hello {schoolName}.`,
      total: `You currently have {0}, that have submitted a total of {1}.`,
      latest: `The most recent test submitted was {0} by {1} on {2}. This test was {3}.`,
      attention: `Currently {0} require special attention.`,
    },
  },
  user: {
    presence: 'Presence Learning',
    distance: 'Distance Learning',
    staff: 'Staff',
  },
  label: {
    userType: 'User Type',
    progressOverview: 'Progress Overview',
    overall: 'Overall',
    submitted: 'Submitted',
    directlyAssignedTests: 'Directly assigned tests',
    atoTests: 'By ATO assigned Tests',
    imprint: 'Imprint',
    insight: 'Insight',
    granted: 'Granted',
    denied: 'Denied',
    studentLogin: 'Student Login',
    atoLogin: 'ATO Login',
    attention: 'Attention',
    testConsistsOf: 'This test consists of the following sections',
    search: 'Search',
    state: 'State',
    all: 'All',
    explanation: 'Explanation',
    test: 'Test',
    givenAnswer: 'Given Answer',
    student: 'Student',
    result: 'Result',
    lastResult: 'Last Result',
    deliveredTests: 'Delivered Tests',
    assignedTests: 'Assigned Tests',
    id: 'ID',
    login: 'Login',
    name: 'Name',
    forename: 'Forename',
    surname: 'Surname',
    validFrom: 'Valid From',
    validUntil: 'Valid Until',
    createdAt: 'Created at',
    phoneNr: 'Phone Nr.',
    schoolClass: 'School Class',
    timeLimit: 'Time Limit',
    yourAnswer: 'Your Answer',
    unlimited: 'Unlimited',
    time: 'Time',
    submittedTests: 'Submitted Tests',
    submittedPreExams: 'Submitted Pre-Exams',
    start: 'Start',
    stop: 'Stop',
    duration: 'Duration',
    date: 'Date',
    title: 'Title',
    email: 'Email',
    loginName: 'Login Name',
    password: 'Password',
    rememberMe: 'Remember Me',
    clientLogin: 'Client Login',
    passed: 'Passed',
    notPassed: 'Not yet Passed',
    attempted: 'Open',
    passRate: 'Percentage to pass',
    submittedAt: 'Submitted at',
    percentage: 'Percentage',
    dependentOn: 'Dependent on',
    dependants: 'Dependants',
    status: 'Status',
    testStartFrom: 'Training Start Date',
    testEndDate: 'Training End Date',
    available: 'Available',
    locked: 'Locked',
    weakpointAnalysis: 'Weakpoint Analysis',
    weakpointAnalysisFor: 'Weakpoint Analysis for {target}',
    correct: 'Correct',
    incorrect: 'Incorrect',
    coolDown: 'Waiting period between tries',
    hours: 'Hours',
    none: 'None',
    atoPreExams: 'ATO Pre-Exams',
    unauthorized: 'Unauthorized',
    authorized: 'Authorized',
    protected: 'Protected',
    activationCode: 'Activation Code',
    actions: 'Actions',
    stacking: 'Stacking',
    percentaged: 'Percentaged',
    sort: 'Sort',
    ascending: 'Ascending',
    type: 'Type',
    startDate: 'Start Date',
    endDate: 'End Date',
    minPercentage: 'Min. Percentage',
    advancedFilters: 'Advanced Filters',
    default: 'Default',
  },
  action: {
    close: 'Close',
    grantInsight: 'Grant Insight for the student',
    denyInsight: 'Deny Insight for the student',
    back: 'Back',
    reset: 'Reset',
    login: 'Login',
    logout: 'Logout',
    submit: 'Submit',
    cancel: 'cancel',
    confirm: 'Confirm',
    darkTheme: 'Dark Theme',
    giveExtraTry: 'Give extra try',
    removeExtraTry: 'Remove extra try',
    prevItem: 'Previous {item}',
    nextItem: 'Next {item}',
    submitItem: 'Submit {item}',
    startItem: 'Start {item}',
    practiceItem: 'Practice {item}',
    evaluateItem: 'Evaluate {item}',
    assignItem: 'Assign {item}',
    manageItem: 'Manage {item}',
    removeItem: 'Remove {item}',
    authorizeItem: 'Authorize {item}',
    generateItem: 'Generate {item}',
    authorizeAndStartTest: 'Authorize and start test',
    apply: 'Apply',
    showItem: 'Show {item}',
    hideItem: 'Hide {item}',
    printResults: 'Print Results',
    enableZoom: 'Enable Zoom',
    disableZoom: 'Disable Zoom',
    showUserDetails: 'Show User Details',
    update: 'Update',
    changeLanguage: 'Change Language',
  },
  hint: {
    itemsSelected: '{count} {item} selected',
  },
  form: {
    isRequired: '{field} is mandatory.',
    maxLength: '{field} may not be longer than {max} characters.',
    minLength: '{field} must contain at least {min} characters.',
    length: '{field} need to have a length of {length} characters.',
    isInvalidEmail: '{field} is not a valid email address.',
    isNumeric: '{field} needs to be a numeric value.',
  },
};
