var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-4"},[_c('v-toolbar',{attrs:{"dark":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('label.progressOverview')))]),_c('v-spacer'),_c('div',{staticClass:"pt-1",staticStyle:{"width":"250px"}},[_c('v-text-field',{attrs:{"label":_vm.$t('label.search'),"hide-details":""},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}})],1),_c('div',{staticClass:"pl-2 pt-1",staticStyle:{"width":"200px"}},[_c('v-select',{attrs:{"label":_vm.$t('label.userType'),"items":_vm.userTypes,"hide-details":""},model:{value:(_vm.filter.userType),callback:function ($$v) {_vm.$set(_vm.filter, "userType", $$v)},expression:"filter.userType"}})],1)],1),_c('v-data-table',{attrs:{"items":_vm.progressStudents,"headers":_vm.headers,"sort-by":"preExams","sort-desc":"","hide-default-footer":_vm.progressStudents.length <= 10,"must-sort":""},scopedSlots:_vm._u([{key:"item.forename",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{name: 'student-details', params: {id: item.id}}}},[_vm._v(_vm._s(item.forename))])]}},{key:"item.surname",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{name: 'student-details', params: {id: item.id}}}},[_vm._v(_vm._s(item.surname))])]}},{key:"item.progressTests",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.progressTests)+"/"+_vm._s(item.progressTestAssignments)+" ")]}},{key:"item.finalTests",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.finalTests)+"/"+_vm._s(item.finalTestAssignments)+" ")]}},{key:"item.preExams",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.preExams)+"/"+_vm._s(item.preExamAssignments)+" ")]}},{key:"item.overall",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.overall)+"/"+_vm._s(item.overallAssignments)+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }