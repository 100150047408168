import VuexORM from '@vuex-orm/core';
import Vue from 'vue';
import Vuex from 'vuex';
import {models} from '~/models';
import {analysisModule} from '~/store/analysis.module';
import {appModule} from '~/store/app.module';
import {authModule} from '~/store/auth.module';
import {snackbarModule} from '~/store/snackbar.module';
import {studentModule} from '~/store/student.module';
import {testInstanceModule} from '~/store/test-instance.module';
import {testModule} from '~/store/test.module';

Vue.use(Vuex);

const database = new VuexORM.Database();
models.forEach(model => database.register(model));

const store = new Vuex.Store({
  plugins: [VuexORM.install(database)],
  state: {},
  mutations: {},
  actions: {},
  modules: {
    app: appModule,
    analysis: analysisModule,
    auth: authModule,
    snackbar: snackbarModule,
    student: studentModule,
    test: testModule,
    instance: testInstanceModule,
  },
});
export default store;
