import {Module} from 'vuex';
import store from '~/store/index';
import {api} from '~/util';

const UPDATE_THRESHOLD = 60000; // 60 sek

export interface TestState {
  lastTestInstancesFetch: number;
}

export const testModule: Module<TestState, any> = {
  namespaced: true,
  state: {
    lastTestInstancesFetch: 0,
  },
  mutations: {},
  actions: {
    async getTestDetails({state, dispatch}, id) {
      try {
        const response = await api.get(`/api/test/${id}`);
        return response.data;
      } catch (e) {
        await store.dispatch('snackbar/showError', e);
      }
    },
  },
};
