import {Module} from 'vuex';
import {Area, Licence, Subject} from '~/models';
import store from '~/store/index';
import {api} from '~/util';

interface AnalysisState {
}

export const analysisModule: Module<AnalysisState, any> = {
  namespaced: true,
  state: {},
  actions: {
    async getWeakpointData(context, filter: any) {
      try {
        const response = await api.post(`/api/analysis/weakpoint`, filter);
        return response.data;
      } catch (e) {
        await store.dispatch('snackbar/showError', e);
      }
    },
    async fetchLicences() {
      try {
        const response = await api.get(`/api/analysis/weakpoint/licences`);
        await Licence.insert({data: response.data});
      } catch (e) {
        await store.dispatch('snackbar/showError', e);
      }
    },
    async fetchLicence(context, licenceId: number) {
      try {
        const response = await api.get(`/api/analysis/weakpoint/licence/${licenceId}`);
        await Licence.insert({data: response.data});
      } catch (e) {
        await store.dispatch('snackbar/showError', e);
      }
    },
    async fetchSubject(context, subjectId: number) {
      try {
        const response = await api.get(`/api/analysis/weakpoint/subject/${subjectId}`);
        await Subject.insert({data: response.data});
      } catch (e) {
        await store.dispatch('snackbar/showError', e);
      }
    },
    async fetchArea(context, areaId: number) {
      try {
        const response = await api.get(`/api/analysis/weakpoint/area/${areaId}`);
        await Area.insert({data: response.data});
      } catch (e) {
        await store.dispatch('snackbar/showError', e);
      }
    },
  },
};
