import {Module} from 'vuex';
import {TestInstance} from '~/models';
import store from '~/store';
import {api} from '~/util';

const UPDATE_THRESHOLD = 60000; // 60 sek

export interface TestState {
  lastTestInstancesFetch: number;
}

export const testInstanceModule: Module<TestState, any> = {
  namespaced: true,
  state: {
    lastTestInstancesFetch: 0,
  },
  mutations: {},
  actions: {
    async fetchTestInstances({state}, params) {
      if (params || state.lastTestInstancesFetch + UPDATE_THRESHOLD < Date.now()) {
        state.lastTestInstancesFetch = Date.now();
        try {
          const response = await api.get(`/api/test-instance`, {params});
          await TestInstance.insert({data: response.data});
        } catch (e) {
          await store.dispatch('snackbar/showError', e);
        }
      }
    },
    async fetchTestInstanceById({state, dispatch, commit}, id) {
      try {
        const response = await api.get(`/api/test-instance/${id}`);
        await TestInstance.insert({data: response.data});
        return response.data;
      } catch (e) {
        await store.dispatch('snackbar/showError', e);
      }
    },
    async getAdditionalTestInstanceInfo({state, dispatch, commit}, id) {
      try {
        const response = await api.get(`/api/test-instance/${id}/additional`);
        return response.data;
      } catch (e) {
        await store.dispatch('snackbar/showError', e);
      }
    },
    async denyInsight(context, instance: TestInstance) {
      try {
        await api.put(`/api/test-instance/${instance.id}/deny-insight`);
        await TestInstance.update({where: instance.id, data: {insightGranted: false}});
      } catch (e) {
        await store.dispatch('snackbar/showError', e);
      }
    },
    async grantInsight(context, instance: TestInstance) {
      try {
        await api.put(`/api/test-instance/${instance.id}/grant-insight`);
        await TestInstance.update({where: instance.id, data: {insightGranted: true}});
      } catch (e) {
        await store.dispatch('snackbar/showError', e);
      }
    },
  },
};
