















import Vue from 'vue';
import {Student} from '~/models';

export default Vue.extend({
  name: 'AppSidebar',
  data() {
    return {};
  },
  computed: {
    navigation(): any[] {
      const navItems = [];
      if (this.hasUsers('distance')) {
        navItems.push({text: this.$t('nav.distanceStudents'), icon: 'mdi-account-outline', to: 'distance-students'});
      }
      if (this.hasUsers('presence')) {
        navItems.push({text: this.$t('nav.presenceStudents'), icon: 'mdi-account', to: 'presence-students'});
      }
      if (this.hasUsers('script')) {
        navItems.push({text: this.$t('nav.scriptUsers'), icon: 'mdi-account-star', to: 'script-users'});
      }
      if (this.hasUsers('staff')) {
        navItems.push({text: this.$t('nav.staff'), icon: 'mdi-account-tie', to: 'staff'});
      }
      return [
        {text: this.$t('nav.dashboard'), icon: 'mdi-home', to: 'dashboard'},
        ...navItems,
        {text: this.$t('nav.progressTracker'), icon: 'mdi-finance', to: 'progress-tracker'},
      ];
    },
    app() {
      return this.$store.state.app;
    },
  },
  methods: {
    hasUsers(type: string): boolean {
      return Student.all().filter(s => s.type === type).length > 0;
    },
  },
});
