













































import moment from 'moment';
import Vue from 'vue';
import LoginPanel from '~/components/auth/LoginPanel.vue';
import AppSidebar from '~/components/layout/AppSidebar.vue';
import AppToolbar from '~/components/layout/AppToolbar.vue';
import CatFooter from '~/components/layout/CatFooter.vue';

export default Vue.extend({
  components: {LoginPanel, AppToolbar, AppSidebar, CatFooter},
  async created() {
    try {
      moment.locale(this.$i18n.locale);
      await this.$store.dispatch('auth/signInByToken');
    } catch (err) {
      console.log(err);
    }
  },
  computed: {
    isIE() {
      return /MSIE|Trident/.test(window.navigator.userAgent);
    },
    auth() {
      return this.$store.state.auth;
    },
    snackbar() {
      return this.$store.state.snackbar;
    },
    isLoading() {
      return this.$store.getters['app/isLoading'];
    },
  },
});
