














































import Vue from 'vue';
import Moment from '~/components/common/Moment.vue';
import {TestInstance} from '~/models';

enum FilterState {
  All = 0,
  Passed = 1,
  NotPassed = 2,
  Attention = 3,
}

export default Vue.extend({
  name: 'TestInstances',
  components: {Moment},
  data() {
    return {
      search: '',
      state: FilterState.All,
    };
  },
  async created() {
    await this.$store.dispatch('instance/fetchTestInstances');
  },
  computed: {
    states() {
      return [
        {text: this.$t('label.all'), value: FilterState.All},
        {text: this.$t('label.passed'), value: FilterState.Passed},
        {text: this.$t('label.notPassed'), value: FilterState.NotPassed},
        {text: this.$t('label.attention'), value: FilterState.Attention},
      ];
    },
    instances(): TestInstance[] {
      return TestInstance.query().withAll().all();
    },
    filteredInstances(): TestInstance[] {
      const lowerSearch = this.search.toLowerCase();

      return this.instances.filter(instance => {
        return (!lowerSearch || (instance.student.fullName.toLowerCase().includes(lowerSearch) || instance.displayName.toLowerCase().includes(lowerSearch)))
            && (this.state === FilterState.All ||
                this.state === FilterState.Passed && instance.passed ||
                this.state == FilterState.NotPassed && !instance.passed ||
                this.state === FilterState.Attention && !instance.passed && instance.try >= instance.test.tries && instance.test.state !== 'archived'
                && instance.id === this.instances.filter(i => i.testId === instance.testId && i.studentId == instance.studentId).sort((a, b) => b.try - a.try)[0].id);
      });
    },
    headers(): any[] {
      return [
        {text: this.$t('label.forename'), value: 'student.forename'},
        {text: this.$t('label.surname'), value: 'student.surname'},
        {text: this.$tc('label.test'), value: 'displayName'},
        {text: this.$tc('p.try'), value: 'try', align: 'right'},
        {text: this.$tc('p.correctAnswer', 2), value: 'correctAnswers', align: 'right'},
        {text: this.$t('label.percentage'), value: 'percentage', align: 'right'},
        {text: this.$t('label.passed'), value: 'passed', align: 'center'},
        {text: this.$t('label.date'), value: 'finishedAt', align: 'right'},
      ];
    },
  },
  methods: {
    getPercentage(item: TestInstance) {
      return Math.floor(item.correctAnswers / item.questionAmount * 100);
    },
    getColorClass(item: TestInstance) {
      if (item.passed) {
        return 'instance-green';
      }
      if (item.try < item.test.tries) {
        return 'instance-orange';
      }

      const latestTestInstance = this.instances.filter(i => i.testId === item.testId && i.studentId == item.studentId).sort((a, b) => a.try < b.try ? 1 : a.try > b.try ? -1 : 0)[0];
      if (latestTestInstance.id === item.id && latestTestInstance.test.state !== 'archived') {
        return 'instance-red';
      }

      return 'instance-orange';
    },
  },
});
