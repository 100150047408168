



















import Vue from 'vue';

export default Vue.extend({
  name: 'UserMenu',
  data() {
    return {};
  },
  computed: {
    schoolName(): string {
      return this.$store.state.auth.school.name;
    },
    breakpoint() {
      return this.$vuetify.breakpoint;
    },
  },
  methods: {
    async logout() {
      await this.$store.dispatch('auth/signOut');
      if (this.$route.name !== 'dashboard') {
        await this.$router.push({name: 'dashboard'});
      }
    },
  },
});
