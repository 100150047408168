



























import moment from 'moment';
import Vue from 'vue';
import LanguageChanger from '~/components/common/LanguageChanger.vue';
import TooltipButton from '~/components/common/TooltipButton.vue';
import UserMenu from '~/components/layout/UserMenu.vue';

export default Vue.extend({
  name: 'AppToolbar',
  components: {LanguageChanger, UserMenu, TooltipButton},
  computed: {
    schoolLogo() {
      return this.$store.state.auth.school?.logoUrl;
    },
    schoolName() {
      return this.$store.state.auth.school?.name;
    },
    updateTime(): string {
      return moment(this.$store.state.auth.updateTime).format('HH:mm:ss');
    },
  },
  methods: {
    toggleSidebar() {
      this.$store.dispatch('app/toggleSidebar');
    },
    update() {
      this.$store.dispatch('auth/updateAto');
    },
  },
});
