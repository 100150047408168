






























import Vue from 'vue';
import {School, Test, TestInstance} from '~/models';
import Moment from '../common/Moment.vue';

export default Vue.extend({
  name: 'DashboardText',
  components: {Moment},
  computed: {
    school(): School {
      return this.$store.state.auth.school;
    },
    latestInstance(): TestInstance {
      const testInstances = this.testInstances.filter(instance => !!instance.finishedAt);
      if (testInstances.length === 0) {
        return null;
      }
      return testInstances.sort((a, b) => a.finishedAt < b.finishedAt ? 1 : b.finishedAt < a.finishedAt ? -1 : 0)[0];
    },
    tests(): any[] {
      return Test.query().with('instances').all();
    },
    attentionInstances(): TestInstance[] {
      return this.tests.filter(test => {
        if (test.instances.length === 0) {
          return false;
        }
        const latestTestInstance = test.instances.sort((a, b) => a.finishedAt < b.finishedAt ? 1 : b.finishedAt < a.finishedAt ? -1 : 0)[0];
        return !latestTestInstance.passed && test.instances.length >= test.tries && test.state != 'archived';
      });
    },
    testInstances(): TestInstance[] {
      return TestInstance.query().withAll().all();
    },
  },
});
