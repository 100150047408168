import {Model} from '@vuex-orm/core';
import moment from 'moment';
import {Test} from '.';
import {Answer, Question, Student, TestInstanceAnswer, TestInstanceQuestion} from '..';

export class TestInstance extends Model {
  static entity = 'test-instances';

  static fields() {
    return {
      id: this.attr(null),
      title: this.attr(null),
      displayName: this.attr(null),
      timeLimit: this.attr(null),
      passRate: this.attr(null),
      passed: this.attr(null),
      try: this.attr(null),
      tries: this.attr(null),
      questionAmount: this.attr(null),
      testId: this.attr(null),
      test: this.belongsTo(Test, 'testId'),
      studentId: this.attr(null),
      student: this.belongsTo(Student, 'studentId'),
      questions: this.belongsToMany(Question, TestInstanceQuestion, 'questionId', 'testInstanceId'),
      answers: this.belongsToMany(Answer, TestInstanceAnswer, 'answerId', 'testInstanceId'),
      correctAnswers: this.attr(0),

      createdAt: this.attr(''),
      finishedAt: this.attr(''),
      updatedAt: this.attr(''),
      type: this.attr(''),
      insightGranted: this.attr(null),
    };
  }

  id: number;
  title: string;
  displayName: string;
  studentId: string;
  testId: number;
  questionAmount: number;
  try: number;
  tries: number;
  timeLimit: number;
  passRate: number;
  passed: boolean;
  test: Test;
  student: Student;
  questions: Question[];
  answers: Answer[];
  correctAnswers: number;

  createdAt: string;
  finishedAt: string;
  updatedAt: string;
  type: string;
  insightGranted: boolean;


  get isPreExam(): boolean {
    return this.type === 'preExam';
  }

  get isFinal(): boolean {
    return this.type === 'final';
  }

  get isProgress(): boolean {
    return this.type === 'progress';
  }

  get percentage(): number {
    return Math.floor(this.correctAnswers / this.questionAmount * 100);
  }

  get duration(): string {
    const diff = moment(this.finishedAt).diff(this.createdAt);
    return moment.utc(diff).format('HH:mm:ss');
  }

  get typeLabel(): string {
    switch (this.type) {
      case 'preExam':
        return 'p.preExam';
      case 'final':
        return 'p.finalTest';
      case 'progress':
        return 'p.progressTest';
      default:
        return 'label.default';
    }
  }
}
