



































import Vue from 'vue';
import {Student} from '~/models';

export default Vue.extend({
  name: 'ProgressOverview',
  data() {
    return {
      filter: {
        search: '',
        userType: 'all',
      },
    };
  },
  async created() {
    await this.$store.dispatch('instance/fetchTestInstances');
  },
  computed: {
    students(): Student[] {
      return Student.query()
          .with('testInstances')
          .with('tests')
          .with('testGroups.tests')
          .with('studentTestStates.test')
          .all();
    },
    userTypes(): any[] {
      return [
        {text: this.$t('label.all'), value: 'all'},
        {text: this.$t('user.presence'), value: 'presence'},
        {text: this.$t('user.distance'), value: 'distance'},
      ];
    },
    progressStudents(): any[] {
      const lowerSearch = this.filter.search.toLowerCase();
      return this.students
          .filter(student => student.fullName.toLowerCase().includes(lowerSearch))
          .filter(student => (this.filter.userType === 'all' && (student.type === 'presence' || student.type === 'distance')) || this.filter.userType === student.type)
          .map(student => {
            const passedInstances = student.testInstances.filter(instance => instance.passed);
            let tests = student.testGroups.reduce((arr, tg) => arr.concat(tg.tests), student.tests);
            tests = tests.concat(student.studentTestStates.map(sts => sts.test));
            tests = tests.filter((test, index, self) => self.findIndex(t => t.id === test.id) === index); // unique only
            tests = tests.filter(test => test.type !== 'default'); // only typed tests


            return {
              id: student.id,
              forename: student.forename,
              surname: student.surname,
              preExams: passedInstances.filter(instance => instance.isPreExam).length,
              preExamAssignments: tests.filter(test => test.isPreExam).length,
              progressTests: passedInstances.filter(instance => instance.isProgress).length,
              progressTestAssignments: tests.filter(test => test.isProgress).length,
              finalTests: passedInstances.filter(instance => instance.isFinal).length,
              finalTestAssignments: tests.filter(test => test.isFinal).length,
              overall: passedInstances.length,
              overallAssignments: tests.length,
            };
          })
          .filter(ps => ps.overallAssignments > 0);
    },
    headers(): any[] {
      return [
        {text: this.$t('label.forename'), value: 'forename'},
        {text: this.$t('label.surname'), value: 'surname'},
        {text: this.$tc('p.progressTest', 2), value: 'progressTests', align: 'right'},
        {text: this.$tc('p.finalTest', 2), value: 'finalTests', align: 'right'},
        {text: this.$tc('p.preExam', 2), value: 'preExams', align: 'right'},
        {text: this.$t('label.overall'), value: 'overall', align: 'right'},
      ];
    },
  },
  methods: {},
});
