import {Module} from 'vuex';
import {School, Test, TestGroupTest, TestStudent} from '~/models';
import {api} from '~/util';

interface AuthState {
  isAuthPending: boolean;
  isAuthenticated: boolean;
  accessToken: string;
  refreshToken: string;
  refreshTimeoutId: any;
  school: School;
  updateTime: Date;
}

export const authModule: Module<AuthState, any> = {
  namespaced: true,
  state: {
    isAuthPending: true,
    isAuthenticated: false,
    accessToken: '',
    refreshToken: '',
    refreshTimeoutId: 0,
    school: null,
    updateTime: new Date(),
  },
  mutations: {
    setAuthPending(state, isPending) {
      state.isAuthPending = isPending;
    },
    setAccessToken(state, token) {
      state.accessToken = token;
    },
    setProfile(state, school) {
      state.school = school;
      state.isAuthenticated = true;
      state.isAuthPending = false;
      state.updateTime = new Date();
    },
    signedOut(state) {
      state.isAuthenticated = false;
      state.accessToken = null;
      state.school = null;
    },
  },
  actions: {
    async signIn({commit, dispatch}, credentials) {
      const response = await api.post('/api/auth/sign-in', credentials);
      await Promise.all([
        School.insert({data: response.data.profile.profile}),
        Test.insert({data: response.data.profile.tests}),
        TestGroupTest.insert({data: response.data.profile.testHasTestGroups}),
        TestStudent.insert({data: response.data.profile.studentHasTests}),
      ]);

      const school = School.query().whereId(response.data.profile.profile.id).withAll().with('testGroups.tests').first();
      commit('setAccessToken', response.data.token);
      commit('setProfile', school);
      dispatch('renewToken', response.data.token);
    },
    async signInByToken({commit, dispatch}) {
      try {
        const response = await api.post('/api/auth/refresh');

        await Promise.all([
          School.insert({data: response.data.profile.profile}),
          Test.insert({data: response.data.profile.tests}),
          TestGroupTest.insert({data: response.data.profile.testHasTestGroups}),
          TestStudent.insert({data: response.data.profile.studentHasTests}),
        ]);

        const school = School.query().whereId(response.data.profile.profile.id).withAll().with('testGroups.tests').first();
        commit('setAccessToken', response.data.token);
        commit('setProfile', school);
        dispatch('renewToken', response.data.token);
      } catch (err) {
        commit('setAuthPending', false);
      }
    },
    async signOut(context) {
      clearTimeout(context.state.refreshTimeoutId);
      await api.get('/api/auth/sign-out');
      context.commit('signedOut');
    },
    async refreshToken({commit, dispatch}) {
      const response = await api.post('/api/auth/refresh');
      commit('setAccessToken', response.data.token);
      dispatch('renewToken', response.data.token);
    },
    renewToken(context, token) {
      const payloadString = token.split('.')[1];
      const payload = JSON.parse(atob(payloadString));
      const expiresIn = payload.exp * 1000 - Date.now();
      // request fresh access-token 10s before it will expire
      context.state.refreshTimeoutId = setTimeout(() => context.dispatch('refreshToken'), expiresIn - 10000);
    },
    async updateAto({commit}) {
      const response = await api.post('/api/auth/update');
      const school = School.query().whereId(response.data.profile.id).withAll().with('testGroups.tests').first();

      await Promise.all([
        School.insertOrUpdate({data: response.data.profile}),
        Test.insertOrUpdate({data: response.data.tests}),
        TestGroupTest.insertOrUpdate({data: response.data.testHasTestGroups}),
        TestStudent.insertOrUpdate({data: response.data.studentHasTests}),
      ]);

      commit('setProfile', school);
    },
  },
  getters: {},
};
