export const de = {
  nav: {
    dashboard: 'Dashboard',
    presenceStudents: 'Präsenzschüler',
    distanceStudents: 'Fernschüler',
    staff: 'Mitarbeiter',
    scriptUsers: 'Skriptnutzer',
    progressTracker: 'Lernfortschritte',
  },
  p: {
    correctAnswer: 'Richtige Antwort | Richtige Antworten',
    licence: 'Lizenz | Lizenzen',
    subject: 'Thema | Themen',
    area: 'Bereich | Bereiche',
    subArea: 'Unterbereich | Unterbereiche',
    question: 'Frage | Fragen',
    staticQuestion: 'Statische Frage | Statische Fragen',
    testDelivery: 'Test Abgabe | Test Abgaben',
    try: 'Versuch | Versuche',
    minute: 'Minute | Minuten',
    answer: 'Antwort | Antworten',
    student: 'Schüler | Schüler',
    test: 'Test | Tests',
    testGroup: 'Testgruppe | Testgruppen',
    testPractice: 'Testübung | Testübungen',
    atoPreExam: 'ATO Pre-Exam | ATO Pre-Exams',
    preExam: 'Pre-Exam | Pre-Exams',
    progressTest: 'Progress-Test | Progress-Tests',
    finalTest: 'Final-Test | Final-Tests',
    pool: 'Pool | Pools',
  },
  msg: {
    latestUpdate: 'Zuletzt aktualisiert um {time} Uhr',
    authorizeAllPreExams: {
      title: 'Alle Pre-Exams Authorisieren',
      text: 'Die gewählten Einstellungen werden auf ALLE dem Schüler zugeordneten Pre-Exams angewandt. Falls abweichende Einstellungen zuvor für einzelne oder mehrere Pre-Exams gewählt wurden, werden diese hierbei einheitlich überschrieben.',
    },
    confirmExtraTry: {
      title: 'Weiteren Versuch Bestätigen',
      text: 'Dieses Pre-Exam wurde bereits bestanden. Bitte bestätigen Sie, dass der Schüler dieses Pre-Exam erneut absolvieren soll.',
    },
    additionalTryGiven: 'Ein weiterer Versuch wurde für den Schüler freigeschaltet.',
    noAnswerGiven: 'Nicht beantwortet',
    dashboard: {
      hello: `Hallo {schoolName}.`,
      login: `Hallo {0},{1}Dein letzter Login war am {2}.`,
      total: `Du hast aktuell {0}, die {1} abgegeben haben.`,
      latest: `Der letzte abgegebene Test war {0} von {1} am {2}. Dieser Test wurde {3}.`,
      attention: `Aktuell erfordern {0} besondere Aufmerksamkeit.`,
    },
  },
  user: {
    presence: 'Präsenzschüler',
    distance: 'Fernschüler',
    staff: 'Mirarbeiter',
  },
  label: {
    userType: 'Benutzer Typ',
    progressOverview: 'Fortschritt Übersicht',
    overall: 'Gesamt',
    submitted: 'Eingereicht',
    directlyAssignedTests: 'Direkt zugewiesene Tests',
    atoTests: 'Von ATO zugewiesene Tests',
    imprint: 'Impressum',
    insight: 'Einsicht',
    granted: 'Gewährt',
    denied: 'Entzogen',
    studentLogin: 'Schüleranmeldung',
    atoLogin: 'ATO Anmeldung',
    attention: 'Aufmerksamkeit',
    testConsistsOf: 'Dieser Test besteht aus den folgenden Sektionen',
    search: 'Suche',
    state: 'Zustand',
    all: 'Alle',
    explanation: 'Erklärung',
    test: 'Test',
    givenAnswer: 'Antwort',
    student: 'Schüler',
    result: 'Ergebnis',
    lastResult: 'Letztes Ergebnis',
    deliveredTests: 'Abgegebene Tests',
    assignedTests: 'Zugewiesene Tests',
    id: 'ID',
    login: 'Anmeldename',
    name: 'Name',
    forename: 'Vorname',
    surname: 'Nachname',
    validFrom: 'Gültig ab',
    validUntil: 'Gültig bis',
    createdAt: 'Erstellt am',
    phoneNr: 'Telefon Nr.',
    schoolClass: 'Flugschulklasse',
    timeLimit: 'Zeitlimit',
    yourAnswer: 'Deine Antwort',
    unlimited: 'Unbegrenzt',
    time: 'Zeit',
    submittedTests: 'Abgegebene Tests',
    submittedPreExams: 'Abgegebene Pre-Exams',
    start: 'Start',
    stop: 'Stop',
    duration: 'Dauer',
    date: 'Datum',
    title: 'Titel',
    email: 'Email',
    loginName: 'Anmeldename',
    password: 'Passwort',
    rememberMe: 'Eingeloggt bleiben',
    clientLogin: 'Kundenlogin',
    passed: 'Bestanden',
    notPassed: 'Noch nicht bestanden',
    attempted: 'Offen',
    passRate: 'Prozentsatz zum Bestehen',
    submittedAt: 'Abgegeben am',
    percentage: 'Prozentsatz',
    dependentOn: 'Ist abhängig von',
    dependants: 'Hat abhängigen Test',
    status: 'Status',
    testStartFrom: 'Ausbildungsbeginn',
    testEndDate: 'Ausbildungsende',
    available: 'Verfügbar',
    locked: 'Gesperrt',
    weakpointAnalysis: 'Schwachstellen Analyse',
    weakpointAnalysisFor: 'Schwachstellen Analyse für {target}',
    correct: 'Richtig',
    incorrect: 'Falsch',
    coolDown: 'Wartezeit zwischen Versuchen',
    hours: 'Stunden',
    none: 'Keine',
    atoPreExams: 'ATO Pre-Exams',
    unauthorized: 'Nicht Authorisiert',
    authorized: 'Authorisiert',
    protected: 'Geschützt',
    activationCode: 'Aktivierungs-Code',
    actions: 'Aktionen',
    stacking: 'Gestapelt',
    percentaged: 'Prozentual',
    sort: 'Sortieren',
    ascending: 'Aufsteigend',
    type: 'Typ',
    startDate: 'Start Datum',
    endDate: 'End Datum',
    minPercentage: 'Min. Prozentsatz',
    advancedFilters: 'Erweiterte Filter',
    default: 'Standard',
  },
  action: {
    close: 'Schließen',
    grantInsight: 'Dem Schüler Einsicht gewähren',
    denyInsight: 'Dem Schüler Einsicht entziehen',
    back: 'Zurück',
    reset: 'Zurücksetzen',
    login: 'Anmelden',
    logout: 'Abmelden',
    submit: 'Abschicken',
    cancel: 'Abbrechen',
    confirm: 'Bestätigen',
    darkTheme: 'Dunkles Layout',
    giveExtraTry: 'Weiteren Versuch freischalten',
    removeExtraTry: 'Weiteren Versuch entziehen',
    prevItem: 'Vorheriger {item}',
    nextItem: 'Nächster {item}',
    submitItem: '{item} Abschicken',
    startItem: '{item} Starten',
    practiceItem: '{item} Üben',
    evaluateItem: '{item} auswerten',
    assignItem: '{item} zuweisen',
    manageItem: '{item} verwalten',
    removeItem: '{item} entfernen',
    authorizeItem: '{item} freigeben',
    generateItem: '{item} generieren',
    authorizeAndStartTest: 'Aktivieren und test starten',
    apply: 'Anwenden',
    showItem: '{item} anzeigen',
    hideItem: '{item} verbergen',
    printResults: 'Ergebnisse Drucken',
    enableZoom: 'Zoom Aktivieren',
    disableZoom: 'Zoom Deaktivieren',
    showUserDetails: 'Benutzerdetails anzeigen',
    update: 'Aktualisieren',
    changeLanguage: 'Sprache ändern',
  },
  hint: {
    itemsSelected: '{count} {item} ausgewählt',
  },
  form: {
    isRequired: '{field} ist ein Pflichtfeld.',
    maxLength: '{field} darf nicht mehr als {max} Zeichen enthalten.',
    minLength: '{field} muss mindestens {min} Zeichen enthalten.',
    length: '{field} muss genau {length} Zeichen enthalten.',
    isInvalidEmail: '{field} ist keine gültige E-Mail Adresse.',
    isNumeric: '{field} muss einen Zahlenwert enthalten.',
  },
};
